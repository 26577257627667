// utils
import { useIsomorphicLayoutEffect } from 'utils/hooks/use-isomorphic-layout-effect';
import { useIsPreview } from 'utils/hooks/useIsPreview';

const isCC = (isPreview: boolean) =>
  isPreview && typeof window !== 'undefined' && window.location !== window.parent.location;

export function useScrollToItem(page: Content) {
  const isPreview = useIsPreview();

  useIsomorphicLayoutEffect(() => {
    let timeout: Timeout | undefined;

    if (isCC(isPreview)) {
      const scrollToItem = window.sessionStorage.getItem('cc_scroll');
      timeout = setTimeout(() => {
        if (scrollToItem) window.scrollTo(0, parseInt(scrollToItem));
        window.sessionStorage.removeItem('cc_scroll');
      }, 300);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [page]);
}
