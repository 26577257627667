import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';
import debounce from 'lodash.debounce';

export function useScrollToSection(hasHistorySection: boolean) {
  useIsomorphicLayoutEffect(() => {
    if (hasHistorySection) return;

    let pageHeaderHeight = 0;
    const pageHeader = document.getElementById('page-header');
    if (pageHeader) {
      pageHeaderHeight = pageHeader.offsetHeight;
    }

    const getSectionId = () => {
      const hash = window.location.hash;
      if (!hash) return undefined;

      let sectionId: string | undefined = hash.slice(1);
      if ((hash.match(/#/g) || []).length > 1) {
        const hashFragment = hash.split('#')?.at(1);
        if (!hashFragment) return undefined;

        const hashes = decodeURIComponent(hashFragment);
        const splittedHash = hashes?.split('|');
        sectionId = splittedHash?.at(-1);
      }

      return sectionId;
    };

    let observer: MutationObserver;

    function onElementAvailable(elementId, callback) {
      const debouncedCallback = debounce((element) => {
        callback(element);
        observer.disconnect();
      }, 250);

      observer = new MutationObserver((_) => {
        const element = document.getElementById(elementId);
        if (element) {
          debouncedCallback(element);
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    }

    const sectionId = getSectionId();
    if (sectionId)
      onElementAvailable(sectionId, (section) => {
        section.style.scrollMarginTop = `${pageHeaderHeight}px`;
        section.scrollIntoView({ block: 'start' });
      });

    return () => {
      if (observer) observer.disconnect();
    };
  }, [hasHistorySection]);
}
